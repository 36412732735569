<template>
  <div class="position-relative">
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <img class="logo-container" src="@/assets/logo.svg" />
    <v-row class="no-gutters login-container">
      <!-- Left container -->
      <v-col class="col-3 image-container exact-center">
        <ImageSection />
      </v-col>
      <!-- Right container (form section) -->
      <v-col class="exact-center">
        <div class="form-container exact-center">
          <div class="form-width">
            <div class="font-weight-500 header">New Password</div>
            <div class="font-weight-500 login-header mt-7">Create new password</div>
            <div class="content">Set a new password below</div>

            <!-- Form field -->
            <TextField
              class="mt-7"
              label="New Password"
              :required="true"
              :value="formData.password"
              inputType="password"
              @change="getFormData($event, 'password')"
              @onEnter="enterClicked"
            />
            <TextField
              label="Confirm Password"
              :required="true"
              inputType="password"
              :value="formData.confirmPassword"
              @change="getFormData($event, 'confirmPassword')"
              @onEnter="enterClicked"
            />
            <div class="justify-end">
              <Button
                type="primary"
                label="Save"
                width="94px"
                @click="onSave"
                :disabled="isSubmitDisabled() || isLoading"
                :loading="isLoading"
              />
            </div>
          </div>
        </div>
        <div class="position-absolute login-rights">2021 Shiok All rights reserved</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import ImageSection from "../ImageSection.vue";
export default {
  name: "LoginIndex",
  mixins: [validationMixin],
  components: {
    ImageSection,
  },
  validations: {
    formData: {
      password: { required },
      confirmPassword: { required, sameAs: sameAs("password") },
      resetToken: { required },
    },
  },
  data() {
    return {
      isLoading: false,
      type: "",
      notificationMessage: "",
      isNotification: false,
      notificationKey: 0,
      formData: {
        password: "",
        confirmPassword: "",
        resetToken: "",
      },
    };
  },
  methods: {
    ...mapActions({
      resetAdminPassword: "admin/authentication/resetPassword",
      resetMerchantPassword: "merchant/authentication/resetPassword",
      resetLogisticPassword: "logistics/authentication/resetPassword",
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    enterClicked() {
      if (!this.isSubmitDisabled()) {
        this.onSave();
      }
    },
    async onSave() {
      this.isLoading = true;
      try {
        if (this.type === "merchant") {
          await this.resetMerchantPassword(this.formData);
          this.isLoading = false;
        } else if (this.type === "logistic") {
          await this.resetLogisticPassword(this.formData);
          this.isLoading = false;
        } else {
          await this.resetAdminPassword(this.formData);
          this.isLoading = false;
        }
        this.notificationMessage = "Password Updated Successfully";
        this.isNotification = true;
        this.notificationKey += 1;
        setTimeout(() => {
          this.$router.push("/auth/login/admin").catch(() => {});
        }, 1000);
      } catch (err) {
        const { data } = err.response;
        this.notificationMessage = data?.message;
        this.isNotification = true;
        this.notificationKey += 1;
        this.isLoading = false;
      }
    },
  },
  created() {
    if (this.$route?.query?.token) {
      this.formData.resetToken = this.$route?.query?.token;
      this.type = this.$route?.query?.type;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@import "@/styles/auth.scss";
</style>
