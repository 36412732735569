<template>
  <div>
    <img class="image-layout mb-6" src="@/assets/icons/login-img.svg" />
    <div class="header-title justify-center mt-6">Deliver Smarter</div>
    <div class="content display-flex justify-center mt-6">
      {{ lorem }}
    </div>
    <div class="content display-flex justify-center mt-3">
      <div v-for="i in 3" :key="i">
        <img class="ml-2" src="@/assets/icons/diamond-icon.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageSection",
  data() {
    return {
      lorem:
        "Bringing together merchants, logistics partners and customers for the ultimate one-stop solution for courier services and delivery management – right at your fingertips.",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
.image-layout {
  width: 100%;
}
.header-title {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
}
.content {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  padding: 0 1rem;
  text-align: center;
}
</style>
